<template>
    <div v-for="booking in bookings" class="order my-3 bg-light border-bottom">
        <div class="row">
            <div class="col-lg-4">
                <div class="d-flex flex-column justify-content-between order-summary">
                    <div class="d-flex align-items-center">
                        <div class="text-uppercase">Booking #{{ booking.id }}</div>
                        <!--                        <div class="green-label ms-auto text-uppercase">cod</div>-->
                    </div>
                    <div class="fs-8 my-3">{{ booking.vehicle }}</div>
                    <div class="fs-8">{{ booking.pick_up_date }} | {{ booking.pick_up_time }}</div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="align-items-sm-start justify-content-sm-between">
                    <div class="status">Status : {{ booking.status }}</div>
                    <div class="fs-8 my-2">Distance: {{ booking.distance }}</div>
                    <div class="fs-8 my-2">Passenger: {{ booking.number_of_passenger }}</div>
                    <div class="fs-8 my-2">Child: {{ booking.child_count }}</div>
                    <div class="fs-8">Luggage: {{ booking.luggage_count }}</div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="align-items-sm-start justify-content-sm-between float-end">
                    <router-link :to="`/booking/${booking.id}`" class="btn btn-primary text-uppercase">
                        booking info
                    </router-link>
                </div>
                <div class="align-items-sm-start justify-content-sm-between float-end me-4">
                    <select @change="$emit('bookingStatusUpdate', $event, booking)"
                            v-if="booking.status === 'Pending' && booking.check_24_hours" name="status"
                            class="form-control form-control-sm mt-2">
                        <option v-for="bookingStatus in status" :value="bookingStatus">
                            {{ bookingStatus }}
                        </option>
                    </select>
                    <span v-else class="badge rounded-pill text-bg-primary">{{ booking.status }}</span>
                </div>
                <div v-if="!booking.has_rating_review"
                     class="align-items-sm-start justify-content-sm-between float-end mt-3">
                    <button @click="selected_booking=booking" type="button" class="btn btn-success text-uppercase"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                        Add Review
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ratings and Reviews</h5>
                    <button type="button" id="rating-review-modal-close" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <form @submit.prevent="ratingReviewSubmit()">
                    <div class="modal-body">
                        <div class="row g-3 align-items-center">
                            <div class="col-auto">
                                <label for="inputPassword6" class="col-form-label">Rating</label>
                            </div>
                            <div class="col-auto">
                                <div class="rating">
                                    <input v-model="form.rating" type="radio" id="star5" name="rating" value="5"/><label
                                    for="star5" title="Meh">5 stars</label>
                                    <input v-model="form.rating" type="radio" id="star4" name="rating" value="4"/><label
                                    for="star4" title="Kinda bad">4 stars</label>
                                    <input v-model="form.rating" type="radio" id="star3" name="rating" value="3"/><label
                                    for="star3" title="Kinda bad">3 stars</label>
                                    <input v-model="form.rating" type="radio" id="star2" name="rating" value="2"/><label
                                    for="star2" title="Sucks big tim">2 stars</label>
                                    <input v-model="form.rating" type="radio" id="star1" name="rating" value="1"/><label
                                    for="star1" title="Sucks big time">1 star</label>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 mt-2">
                            <label for="review" class="form-label">Review</label>
                            <textarea v-model="form.review" rows="4" class="form-control"
                                      placeholder="Review"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>

import {ref}               from "vue";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";


const props = defineProps({
    bookings : Array,
    status   : Array,
    toDayDate: String,
})

const selected_booking = ref({})

const form = ref({
    rating: '',
    review: '',
})

const ratingReviewSubmit = () => {
    ApiService.post(`/rating-review-save/${selected_booking.value.id}`, form.value).then(({data}) => {
        NotificationService.success(data.message);
        selected_booking.value.has_rating_review = true
        document.getElementById('rating-review-modal-close').click()
        resetForm()
    }).catch(error => {
        NotificationService.error(error.response.data.message);
    })
}

const resetForm = () => {
    form.value = {
        rating: '',
        review: '',
    }
}

</script>

<style scoped>
.rating {
//float:left;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
  follow these rules. Every browser that supports :checked also supports :not(), so
  it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input {
    position: absolute;
    top: -9999px;
    clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
    float: right;
    width: 1em;
    /* padding:0 .1em; */
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    /* line-height:1.2; */
    color: #ddd;
}

.rating:not(:checked) > label:before {
    content: '★ ';
}

.rating > input:checked ~ label {
    color: dodgerblue;

}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
    color: dodgerblue;

}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
    color: dodgerblue;

}

.rating > label:active {
    position: relative;
    top: 2px;
    left: 2px;
}
</style>
